export default function Navbar() {
    let scrollPos = window.scrollY;
    const navbar = document.querySelector('nav'),
        navHeight = document.querySelector('.nav__logo').offsetHeight,
        navHamburger = document.getElementById('js-menu'),
        navMenu = document.querySelector('.nav__menu'),
        addClassOnScroll = () => {
            navbar.classList.add('scroll');
        },
        removeClassOnTop = () => {
            navbar.classList.remove('scroll');
        },
        expandMenu = () => {
            navHamburger.classList.toggle('active');
            navMenu.classList.toggle('active');
            if (scrollPos <= navHeight) {
                navbar.classList.toggle('scroll');
            }
        };

    navHamburger.addEventListener('click', expandMenu);

    window.addEventListener('hashchange', () => {
        if (isCurrentMobile().matches && navMenu.classList.contains('active')) {
            expandMenu();
        }
    });

    document.addEventListener('DOMContentLoaded', () => {
        scrollPos = window.scrollY;

        if (
            scrollPos >= navHeight ||
            (scrollPos <= navHeight && navMenu.classList.contains('active'))
        ) {
            addClassOnScroll();
        } else {
            removeClassOnTop();
        }
    });

    window.addEventListener('scroll', () => {
        scrollPos = window.scrollY;

        if (
            scrollPos >= navHeight ||
            (scrollPos <= navHeight && navMenu.classList.contains('active'))
        ) {
            addClassOnScroll();
        } else {
            removeClassOnTop();
        }
    });

    const childrenMenus = document.querySelectorAll('.menu-item-has-children');
    const childrenMenusElements = document.querySelectorAll(
        '.menu-item-has-children'
    );

    checkMobileNavbar();

    window.onresize = function () {
        checkMobileNavbar();
    };

    function checkMobileNavbar() {
        let mobile = window.matchMedia(
            '(min-width: 0px) and (max-width: 992px)'
        );
        if (mobile.matches) {
            renderSubMenuTitle();
        } else {
            removeSubMenuTitle();
        }
    }
    function renderSubMenuTitle() {
        childrenMenusElements.forEach(menuItem => {
            let subMenu = menuItem.querySelector('.sub-menu');

            if (subMenu && !subMenu.querySelector('.sub-menu__back')) {
                const subMenuTitle = menuItem.querySelector('a').innerHTML;
                const newElement = document.createElement('div');
                newElement.innerHTML = subMenuTitle;
                newElement.className = 'sub-menu__back';

                newElement.addEventListener('click', () => {
                    subMenu.classList.remove('active');
                });

                subMenu.insertAdjacentElement('afterbegin', newElement);
            }
        });
    }

    function removeSubMenuTitle() {
        childrenMenusElements.forEach(menuItem => {
            let subMenu = menuItem.querySelector('.sub-menu');
            let subMenuBack = subMenu.querySelector('.sub-menu__back');

            if (subMenuBack) {
                subMenuBack.remove();
            }
        });
    }

    childrenMenus.forEach(menu => {
        menu.addEventListener('click', e => {
            if (!e.target.href) {
                e.preventDefault();
                let mobile = isCurrentMobile();
                let subMenu = e.target.querySelector('.sub-menu');
                if (mobile.matches && subMenu) {
                    subMenu.classList.add('active');
                }
            }
        });
    });

    let isCurrentMobile = () => {
        let mobile = window.matchMedia(
            '(min-width: 0px) and (max-width: 992px)'
        );
        return mobile;
    };
}
