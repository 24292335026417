export default class Select {
    constructor(el, parentEl = null) {
        if (!this.set(el, parentEl)) return;
        this.init();
    }

    set(el, parentEl) {
        if (!el) return false;
        this.root = el;
        this.parent = parentEl;
        this.current = this.root.querySelector('[data-select-current]');
        this.currentText = this.root.querySelector(
            '[data-select-current-text]'
        );
        this.select = this.root.querySelector('[data-select-select]');
        this.items = this.root.querySelectorAll('[data-select-item]');
        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.value = null;

        return true;
    }

    init() {
        this.initSelect();
        this.bind();
    }

    initSelect() {
        if (!this.select || !this.items) return;
        const { initValue, placeholder } = this.settings;

        if (initValue) {
            const initValueItem = this.root.querySelector(
                `[data-select-item-value='${initValue}']`
            );
            const value = initValueItem.getAttribute([
                'data-select-item-value',
            ]);
            const label = initValueItem.getAttribute([
                'data-select-item-label',
            ]);

            this.select.value = value;
            this.setCurrentText(label);
            this.setCurrentItem(initValueItem);
        } else {
            const value = this.items[0].getAttribute([
                'data-select-item-value',
            ]);
            const label = this.items[0].getAttribute([
                'data-select-item-label',
            ]);

            this.select.value = value;
            this.setCurrentText(label);
            this.setCurrentItem(this.items[0]);
        }
    }

    bind() {
        this.bindCurrent();
        this.bindItems();
    }

    bindCurrent() {
        if (!this.current) return;

        this.onCurrentClick = this.onCurrent.bind(this);
        this.current.addEventListener('click', this.onCurrentClick);
    }

    bindItems() {
        if (!this.items) return;
        this.onItemClick = this.onItem.bind(this);

        this.items.forEach(item => {
            item.addEventListener('click', event => this.onItem(event));
        });
    }

    onCurrent() {
        this.root.classList.toggle('active');
    }

    onItem(event) {
        if (!this.select) return;

        const { currentTarget } = event;
        const value = currentTarget.getAttribute('data-select-item-value');
        const label = currentTarget.getAttribute('data-select-item-label');

        this.select.value = value;
        this.root.classList.remove('active');
        this.root.classList.remove('empty');
        this.setCurrentText(label);
        this.resetItems();
        this.setCurrentItem(currentTarget);

        if (this.parent) {
            const event = new CustomEvent('custom-select-change', {
                detail: value,
            });

            const changeEvent = new CustomEvent('custom-select-value-change', {
                detail: {
                    value,
                    id: this.settings.id,
                },
            });

            this.parent.dispatchEvent(event);
            this.parent.dispatchEvent(changeEvent);
        }
    }

    setCurrentText(text) {
        if (!text || !this.currentText) return;
        this.currentText.innerHTML = text;
    }

    resetItems() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.classList.remove('active');
        });
    }

    setCurrentItem(item) {
        item.classList.add('active');
    }

    setInitState() {
        const { placeholder } = this.settings;
        this.root.classList.add('empty');
        this.select.value = null;
        this.resetItems();
        this.items.forEach(item => {
            item.classList.remove('active');
        });
        this.setCurrentText(placeholder);
    }

    toggleDependantItems(values) {
        this.setInitState();
        this.items.forEach(item => {
            const value = item.getAttribute('data-select-item-value');

            if (values.includes(value)) {
                item.classList.remove('d-none');
            } else {
                item.classList.add('d-none');
            }
        });
    }
}
