import Select from './lib/select';

export default function filters() {
    const filtersButton = document.querySelector(
        '.products-title__filters-button'
    );
    const productsFilters = document.querySelector('.products-filters');

    if (!filtersButton && !productsFilters) return;

    const closeButton = productsFilters.querySelector(
        '.products-filters__close'
    );
    const colors = productsFilters.querySelector('.products-colors');
    const radioButtons = productsFilters.querySelectorAll(
        "input[type='radio']"
    );
    const filterButton = productsFilters.querySelector(
        '.products-filters__filter-button'
    );
    const filtersLoader = productsFilters.querySelector(
        '.products-filters__loader'
    );

    const categoryRadio = productsFilters.querySelectorAll(
        "input[name='category_id']"
    );

    const subItems = productsFilters.querySelectorAll(
        '.products-filters__sub input'
    );

    const handleButtonClick = () => {
        if (!productsFilters.classList.contains('-active')) {
            document.documentElement.classList.add('no-scroll');
            productsFilters.classList.add('-active');
        } else {
            document.documentElement.classList.remove('no-scroll');
            productsFilters.classList.remove('-active');
        }
    };

    const handleButtonChange = e => {
        const currentTarget = e.currentTarget;

        if (!currentTarget.classList.contains('checked')) {
            radioButtons.forEach(button => button.classList.remove('checked'));
            currentTarget.classList.add('checked');
        } else {
            currentTarget.checked = false;
            currentTarget.classList.remove('checked');
        }
    };

    const handleFilters = e => {
        let baseUrl = e.currentTarget.getAttribute('data-products-url');
        let fullUrl = baseUrl;
        const urlParts = [];

        const category = productsFilters.querySelector(
            'input[name="category_id"]:checked'
        );
        const shapes = productsFilters.querySelectorAll(
            'input[name="shape_id[]"]:checked'
        );
        const surface = productsFilters.querySelector(
            'input[name="surface_id"]:checked'
        );
        const overflows = productsFilters.querySelectorAll(
            'input[name="overflow_id[]"]:checked'
        );
        const installations = productsFilters.querySelectorAll(
            'input[name="installation_id[]"]:checked'
        );
        const filtersInputTexts =
            productsFilters.querySelectorAll('input[type="text"]');
        const customColorSelect =
            productsFilters.querySelector('[name="color_id"]');

        if (category) {
            fullUrl = category.getAttribute('data-term-link');
            urlParts.push(`category_id=${category.value}`);
        }
        if (installations && installations.length > 0) {
            installations.forEach(installation => {
                urlParts.push(`installation_id[]=${installation.value}`);
            });
        }
        if (shapes && shapes.length > 0) {
            shapes.forEach(shape => {
                urlParts.push(`shape_id[]=${shape.value}`);
            });
        }
        if (surface) {
            urlParts.push(`surface_id=${surface.value}`);
        }
        if (overflows && overflows.length > 0) {
            overflows.forEach(overflow => {
                urlParts.push(`overflow_id[]=${overflow.value}`);
            });
        }

        if (filtersInputTexts && filtersInputTexts.length > 0) {
            filtersInputTexts.forEach(inputText => {
                if (inputText.value) {
                    urlParts.push(`${inputText.name}=${inputText.value}`);
                }
            });
        }

        if (customColorSelect && customColorSelect.value) {
            urlParts.push(`color_id=${customColorSelect.value}`);
        }

        if (urlParts && urlParts.length > 0) {
            urlParts.forEach((urlPart, i) => {
                const paramChar = i === 0 ? '?' : '&';
                fullUrl += `${paramChar}${urlPart}`;
            });
        }

        window.location.href = fullUrl;
    };

    const handleInputToCheck = (inputs, ids, isReset = false) => {
        if (isReset) {
            inputs.forEach(input => {
                input.parentElement.classList.remove('block');
            });
        } else {
            inputs.forEach(input => {
                const wpTermId = parseInt(input.getAttribute('data-filter-id'));

                if (wpTermId) {
                    if (!ids.includes(wpTermId)) {
                        input.parentElement.classList.add('block');
                        input.checked = false;
                    }
                }
            });
        }
    };

    const handleAjaxCall = async () => {
        const checkedRadio = productsFilters.querySelector(
            "input[name='category_id']:checked"
        );

        const inputsToCheck =
            productsFilters.querySelectorAll('.js-check-filter');
        handleInputToCheck(inputsToCheck, false, true);
        const { url } = ajax;
        const formData = new FormData();
        formData.append('action', 'reload_filters');
        formData.append('wp_cat_id', checkedRadio ? checkedRadio.value : '');
        filtersLoader.classList.add('active');
        try {
            const req = await fetch(url, {
                method: 'POST',
                body: formData,
            });
            const response = await req.json();
            const { terms_ids } = response;
            if (inputsToCheck && terms_ids) {
                handleInputToCheck(inputsToCheck, terms_ids, false);
            }
            filtersLoader.classList.remove('active');
        } catch (error) {
            filtersLoader.classList.remove('active');
            console.log(error);
        }
    };
    handleAjaxCall();

    filtersButton.addEventListener('click', handleButtonClick);

    closeButton.addEventListener('click', handleButtonClick);

    if (colors) {
        const customSelectInstance = new Select(colors);
    }

    if (radioButtons) {
        radioButtons.forEach(button => {
            button.addEventListener('click', handleButtonChange);
        });
    }

    if (filterButton) {
        filterButton.addEventListener('click', handleFilters);
    }

    if (categoryRadio) {
        categoryRadio.forEach(catRadio => {
            catRadio.addEventListener('click', () => {
                if (subItems) {
                    subItems.forEach(subItem => {
                        subItem.checked = false;
                    });
                }
                handleAjaxCall();
            });
        });
    }
}
