import Aos from 'aos';
import Navbar from './navbar';

import filters from './filters';
import singleProductsTabs from './single-products-tabs';

var documentReady = function () {
    Navbar();
    singleProductsTabs();
    filters();

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
