export default function singleProductsTabs() {
    const singleProductTabsTitles = document.querySelectorAll(
        '.single-products-tabs__title'
    );

    const singleProductTabs = document.querySelectorAll('.single-products-tab');

    const handleTitleClick = (clickedElement, i) => {
        document
            .querySelector('.single-products-tabs__title.-active')
            .classList.remove('-active');
        document
            .querySelector('.single-products-tab.-active')
            .classList.remove('-active');
        clickedElement.classList.add('-active');
        singleProductTabs[i].classList.add('-active');
    };

    if (!singleProductTabsTitles) return;
    singleProductTabsTitles.forEach((element, i) => {
        element.addEventListener('click', e => {
            if (!e.currentTarget.classList.contains('-active')) {
                handleTitleClick(e.currentTarget, i);
            }
        });
    });
}
